import React from "react";
import { useNavigate } from "react-router-dom";

import { useState } from "react";

// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";

// component
import { Page } from "src/components";
import { Iconify } from "src/components";
import { addInstructor } from "src/DAL/instructor";
const Add = () => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState();
  const [show, setShow] = useState(false);
  const [previewUrl, setPreviewUrl] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [formInputs, setFormInputs] = useState({
    first_name: "",
    last_name: "",
    email: "",
    gender: "male",
    identity_card_number: "",
    phone_number: "",
    status: "true",
    education: "",
    photo: "",
  });

  function handleFile(e) {
    // console.log(e.target.files);
    const file = e.target.files[0];

    setImage(file);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    setShow(false);
  }
  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // console.log(formInputs.gender);
    const data = new FormData();
    data.append("first_name", formInputs.first_name.trim());
    data.append("last_name", formInputs.last_name.trim());
    data.append("email", formInputs.email.trim());
    data.append("gender", formInputs.gender);
    data.append("identity_card_number", formInputs.identity_card_number);
    data.append("phone_number", formInputs.phone_number);
    data.append("education", formInputs.education);
    data.append("image", image);
    data.append("status", formInputs.status);
    // const formDataArray = Array.from(data.entries());

    // console.log(formDataArray);
    // console.log(data);
    const result = await addInstructor(data);
    if (result.code === 200) {
      setIsLoading(false);
      navigate("/instructors");
      enqueueSnackbar("Added Successfully!", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  return (
    <Page title="Add Course">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ marginBottom: "40px", marginTop: "20px" }}
      >
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "40px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>

          <Typography variant="h4" align="center" sx={{ flex: 1 }}>
            Add Instructor
          </Typography>
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="First name"
                  value={formInputs.first_name}
                  onChange={handleChange}
                  name="first_name"
                  required
                  inputProps={{
                    pattern: "[A-Za-z]{3,}",
                    title:
                      "Please enter at least 3 letters with only alphabetical characters.",
                  }}
                />

                <TextField
                  fullWidth
                  label="Last name"
                  value={formInputs.last_name}
                  onChange={handleChange}
                  name="last_name"
                  required
                  inputProps={{
                    pattern: "[A-Za-z]{3,}",
                    title:
                      "Please enter at least 3 letters with only alphabetical characters.",
                  }}
                />
              </Stack>

              <TextField
                fullWidth
                type="email"
                label="Email address"
                value={formInputs.email}
                onChange={handleChange}
                name="email"
                autoComplete="off"
                required
                inputProps={{
                  pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$",
                  title: "Please enter a valid email address",
                }}
              />
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Gender"
                  select
                  required
                  value={formInputs.gender}
                  onChange={handleChange}
                  name="gender"
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                </TextField>

                <TextField
                  fullWidth
                  label="CNIC Number"
                  value={
                    formInputs.identity_card_number
                      ? formInputs.identity_card_number.replace(
                          /(\d{5})(\d{7})(\d{1})/,
                          "$1-$2-$3"
                        )
                      : ""
                  }
                  onChange={handleChange}
                  name="identity_card_number"
                  autoComplete="off"
                  inputProps={{
                    pattern: "[0-9]{5}-[0-9]{7}-[0-9]{1}",
                    title:
                      "Please enter a valid cnic number in the format XXXXX-XXXXXXX-X",
                  }}
                />
              </Stack>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Phone Number"
                  value={formInputs.phone_number}
                  onChange={handleChange}
                  name="phone_number"
                  inputProps={{
                    pattern: "[0-9]{11}",
                    title:
                      "Please enter a valid phone number in the format XXXXXXXXXXX",
                  }}
                />
                <TextField
                  fullWidth
                  label="Status"
                  select
                  value={formInputs.status}
                  onChange={handleChange}
                  name="status"
                  required
                >
                  <MenuItem value="true">Active</MenuItem>
                  <MenuItem value="false">Inactive</MenuItem>
                </TextField>
              </Stack>
              <TextField
                fullWidth
                label="Education"
                value={formInputs.education}
                onChange={handleChange}
                name="education"
              />
              {show === false ? (
                <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                  <label htmlFor="image" className="form-label">
                    Image:
                  </label>
                  <Input
                    type="file"
                    id="image"
                    name="image"
                    accept="image/*"
                    onChange={handleFile}
                  />
                  {/* <Avatar alt={"image"} src={previewUrl} /> */}
                </Stack>
              ) : null}

              {show === true ? (
                <Stack
                  position="relative"
                  alignItems="center"
                  justifyContent="center"
                >
                  {" "}
                  <label htmlFor="image" className="form-label">
                    Image:
                  </label>
                  <Box
                    position="relative"
                    display="inline-block"
                    border="2px solid #ccc"
                    p={1}
                  >
                    <img
                      alt="image"
                      src={previewUrl}
                      width="150"
                      style={{ maxWidth: "100%" }}
                    />
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleDeleteImage()}
                      style={{ position: "absolute", top: 0, right: 0 }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Box>
                </Stack>
              ) : null}

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Add
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </Grid>
    </Page>
  );
};

export default Add;
