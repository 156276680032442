import { invokeApi } from "src/utils";
export const dashboard = async () => {
  const requestObj = {
    path: `api/admin/dash_board`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
// export const batchCount = async () => {
//   const requestObj = {
//     path: `api/batch/count_batches`,
//     method: "GET",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//     },
//   };

//   return invokeApi(requestObj);
// };
// export const courseCount = async () => {
//   const requestObj = {
//     path: `api/course/count_courses`,
//     method: "GET",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//     },
//   };

//   return invokeApi(requestObj);
// };
// export const instructorCount = async () => {
//   const requestObj = {
//     path: `api/instructor/count_instructors`,
//     method: "GET",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//     },
//   };

//   return invokeApi(requestObj);
// };
