import React from "react";

import { useEffect, useState } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
// material
import {
  Avatar,
  IconButton,
  Typography,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

// component
import { CircularLoader, Page } from "src/components";
import { Iconify } from "src/components";

import { detailAdmin } from "src/DAL/auth";
import { s3baseUrl } from "src/config/config";
const Profile = () => {
  const theme = useTheme();

  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };

  const [formInputs, setFormInputs] = useState({
    first_name: "",
    profile_image: "",
    last_name: "",
  });

  const fetchProfile = async () => {
    const response = await detailAdmin();
    if (response.code === 200) {
      setIsLoading(false);
      setFormInputs({
        first_name: response.admin.first_name,
        last_name: response.admin.last_name,
        profile_image: response.admin.profile_image,
        _id: response.admin.user_id._id,
      });
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);
  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Profile">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ marginBottom: "40px", marginTop: "20px" }}
      >
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "40px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>

          <Typography variant="h4" align="center" sx={{ flex: 1 }}>
            Profile
          </Typography>
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <Card sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  // style={{ display: "flex", justifyContent: "center" }}
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Avatar
                    alt={"image"}
                    src={s3baseUrl + formInputs.profile_image}
                    sx={{ width: "120px", height: "120px", mb: 3 }}
                  />
                  <Typography
                    style={{
                      // fontSize: "24px",
                      color: theme.palette.primary.main,
                    }}
                    variant="h4"
                  >
                    {formInputs.first_name} {formInputs.last_name}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  // style={{ display: "flex", justifyContent: "center" }}
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="left"
                  // xl: "left",
                  // lg: "left",
                  // md: "left",
                  sx={{
                    "@media (max-width: 900px)": {
                      alignItems: "center",
                    },
                  }}
                >
                  {" "}
                  <Typography
                    sx={{
                      mb: 3,
                      // backgroundColor: theme.palette.primary.main,
                      // color: "white",
                    }}
                    style={{ fontSize: "18px" }}
                  >
                    <strong
                      style={{
                        fontWeight: "500",

                        // color: theme.palette.primary.main,
                      }}
                    >
                      First Name:
                    </strong>{" "}
                    {formInputs.first_name}
                  </Typography>
                  <Typography style={{ fontSize: "18px" }}>
                    <strong style={{ fontWeight: "500" }}>Last Name: </strong>
                    {formInputs.last_name ? formInputs.last_name : "N/A"}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <LoadingButton
                    size="medium"
                    variant="contained"
                    component={RouterLink}
                    to="/profile/edit-profile"
                    sx={{ width: "120px" }}
                  >
                    Edit Profile
                  </LoadingButton>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Page>
  );
};

export default Profile;
