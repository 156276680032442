import { invokeApi } from "src/utils";
export const listSection = async () => {
  const requestObj = {
    path: `api/section/list_section`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
export const listActivePage = async () => {
  const requestObj = {
    path: `api/page/active_list_page`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
export const delSection = async (id) => {
  const requestObj = {
    path: `api/page/delete_page/` + id,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
export const addSection = async (data) => {
  const requestObj = {
    path: `api/page/add_page`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const detailSection = async (id) => {
  const requestObj = {
    path: `api/page/detail_page/` + id,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const editSection = async (id, data) => {
  const requestObj = {
    path: `api/page/edit_page/` + id,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
