import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard/DashboardLayout";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//pages
import {
  AddUser,
  Dashboard,
  ForgotPassword,
  Login,
  Page404,
  Register,
  Settings,
  Users,
  Instructors,
  AddInstructor,
  EditInstructor,
  Courses,
  AddCourse,
  EditCourse,
  Batches,
  AddBatch,
  EditBatch,
  Students,
  AddStudent,
  EditStudent,
  Pages,
  AddPage,
  EditPage,
  Faqs,
  AddFaq,
  EditFaq,
  Testimonials,
  AddTestimonial,
  EditTestimonial,
  EditProfile,
  Profile,
  ContactList,
  Section,
  AddSection,
  EditSection,
  UpdatePage,
} from "./pages";
import Contact from "./pages/Contact-Us/Contact";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        { path: "dashboard", element: <Dashboard /> },
        { path: "users", element: <Users /> },
        { path: "users/add-user", element: <AddUser /> },
        /////////////
        { path: "batches", element: <Batches /> },
        { path: "batches/add-batch", element: <AddBatch /> },
        {
          path: "batches/edit-batch/:id",
          element: <EditBatch />,
        },
        ////////////
        { path: "courses", element: <Courses /> },
        { path: "courses/add-course", element: <AddCourse /> },
        {
          path: "courses/edit-course/:id",
          element: <EditCourse />,
        },
        ///////////////
        { path: "instructors", element: <Instructors /> },
        { path: "instructors/add-instructor", element: <AddInstructor /> },
        {
          path: "instructors/edit-instructor/:id",
          element: <EditInstructor />,
        },
        /////////////////
        { path: "students", element: <Students /> },
        { path: "students/add-student", element: <AddStudent /> },
        {
          path: "students/edit-student/:id",
          element: <EditStudent />,
        },
        //////////
        { path: "contact", element: <Contact /> },
        { path: "contactlist", element: <ContactList /> },

        ///////////////
        { path: "pages", element: <Pages /> },
        { path: "pages/add-page", element: <AddPage /> },
        {
          path: "pages/edit-page/:id",
          element: <EditPage />,
        },
        //////////

        { path: "testimonials/:id", element: <Testimonials /> },
        { path: "testimonials/add-testimonial", element: <AddTestimonial /> },

        {
          path: "testimonials/edit-testimonial/:id",
          element: <EditTestimonial />,
        },
        ////////
        { path: "faqs/:id", element: <Faqs /> },
        { path: "faqs/add-faq", element: <AddFaq /> },
        {
          path: "faqs/edit-faq/:id",
          element: <EditFaq />,
        },
        ///////////
        { path: "profile", element: <Profile /> },
        { path: "profile/edit-profile", element: <EditProfile /> },
        //////
        { path: "settings", element: <Settings /> },
        //////////
        { path: "section", element: <Section /> },

        { path: "section/add-section", element: <AddSection /> },
        {
          path: "section/edit-section/:id",
          element: <EditSection />,
        },

        { path: "updatepage/:id", element: <UpdatePage /> },
      ],
    },
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: "/", element: <Navigate to="/dashboard" /> },
        { path: "login", element: <Login /> },
        { path: "register", element: <Register /> },
        { path: "forgot-password", element: <ForgotPassword /> },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
