import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { Page } from "src/components";

import { Iconify } from "src/components";

import { addPage } from "src/DAL/page";
import { listActiveTemplate } from "src/DAL/template";
const Add = () => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const { enqueueSnackbar } = useSnackbar();
  const [choose_template, setChoose_template] = useState([]);
  const [brand_logo, setBrand_logo] = useState();
  const [brand_favicon, setBrand_favicon] = useState();
  const [footer_logo, setFooter_logo] = useState();
  const [showbrand_logo, setShowbrand_logo] = useState(false);
  const [previewUrlbrand_logo, setPreviewUrlbrand_logo] = useState();
  const [showbrand_favicon, setShowbrand_favicon] = useState(false);
  const [previewUrlbrand_favicon, setPreviewUrlbrand_favicon] = useState();
  const [showfooter_logo, setShowfooter_logo] = useState(false);
  const [previewUrlfooter_logo, setPreviewUrlfooter_logo] = useState();
  const [links, setLinks] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formInputs, setFormInputs] = useState({
    title: "",
    description: "",
    detail: "",
    choose_template: [],
    is_general: "true",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // console.log(formInputs.gender);
    const data = new FormData();

    data.append("title", formInputs.title);
    data.append("description", formInputs.description);
    data.append("detail", formInputs.detail);
    data.append(
      "choose_template",
      JSON.stringify(
        Array.isArray(formInputs.choose_template)
          ? formInputs.choose_template
          : [formInputs.choose_template]
      )
    );
    data.append("is_general", formInputs.is_general);

    const result = await addPage(data);
    if (result.code === 200) {
      setIsLoading(false);
      navigate("/section");
      enqueueSnackbar("Added Successfully!", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const fetchActiveTemplates = async () => {
    const response = await listActiveTemplate();

    if (response.code === 200) {
      const templetes = response.templetes.map((templete) => ({
        id: templete._id,
        title: templete.template_name,
      }));
      setChoose_template(templetes);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  useEffect(() => {
    fetchActiveTemplates();
  }, []);

  useEffect(() => {
    if (choose_template.length > 0) {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        choose_template: [choose_template[0].id],
      }));
    }
  }, [choose_template]);
  return (
    <Page title="Add Section">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ marginBottom: "40px", marginTop: "20px" }}
      >
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "40px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>

          <Typography variant="h4" align="center" sx={{ flex: 1 }}>
            Add Section
          </Typography>
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Title"
                  value={formInputs.title}
                  onChange={handleChange}
                  name="title"
                  required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />
                <TextField
                  fullWidth
                  label="Description"
                  value={formInputs.description}
                  onChange={handleChange}
                  name="description"
                  autoComplete="off"
                  required
                  multiline
                  rows={1}
                />
              </Stack>
              <TextField
                fullWidth
                label="Detail"
                value={formInputs.detail}
                onChange={handleChange}
                name="detail"
                autoComplete="off"
                required
                multiline
                rows={2}
              />
              <TextField
                fullWidth
                label="Choose Template"
                select
                value={formInputs.choose_template}
                onChange={handleChange}
                name="choose_template"
                required
                // SelectProps={{ multiple: true }}
              >
                {choose_template.map((choose_template) => (
                  <MenuItem key={choose_template.id} value={choose_template.id}>
                    {choose_template.title}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                fullWidth
                label="General"
                select
                required
                value={formInputs.is_general}
                onChange={handleChange}
                name="is_general"
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </TextField>

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Add
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </Grid>
    </Page>
  );
};

export default Add;
