import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

// material
import {
  Stack,
  TextField,
  MenuItem,
  Typography,
  IconButton,
  Grid,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { Page } from "src/components";
import { Iconify } from "src/components";
import { addBatch } from "src/DAL/batch";

import { listActiveCourse } from "src/DAL/course";

const Add = () => {
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [formInputs, setFormInputs] = useState({
    name: "",

    status: "true",
    courses: [],
  });
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = new FormData();
    data.append("name", formInputs.name);
    data.append("status", formInputs.status);

    data.append("courses", JSON.stringify(formInputs.courses));
    // console.log(data.get("courses"));
    const result = await addBatch(data);
    if (result.code === 200) {
      setIsLoading(false);
      navigate("/batches");
      enqueueSnackbar("Added Successfully!", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const fetchActiveCourses = async () => {
    const response = await listActiveCourse();

    if (response.code === 200) {
      const courses = response.courses.map((course) => ({
        id: course._id,
        title: course.title,
        description: course.description,
        duration: course.duration,
        image: course.image,
        status: course.status,
      }));
      setCourses(courses);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  useEffect(() => {
    fetchActiveCourses();
  }, []);
  useEffect(() => {
    if (courses.length > 0) {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        courses: [courses[0].id],
      }));
    }
  }, [courses]);

  return (
    <Page title="Add Batch">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        wrap="wrap"
        sx={{ marginBottom: "40px", marginTop: "20px" }}
      >
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "40px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>

          <Typography variant="h4" align="center" sx={{ flex: 1 }}>
            Add Batch
          </Typography>
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Name"
                  value={formInputs.name}
                  onChange={handleChange}
                  name="name"
                  required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />

                <TextField
                  fullWidth
                  label="Status"
                  select
                  value={formInputs.status}
                  onChange={handleChange}
                  name="status"
                  required
                >
                  <MenuItem value="true">Active</MenuItem>
                  <MenuItem value="false">Inactive</MenuItem>
                </TextField>
              </Stack>

              <TextField
                fullWidth
                label="Courses"
                select
                value={formInputs.courses}
                onChange={handleChange}
                name="courses"
                required
                SelectProps={{ multiple: true }}
              >
                {courses.map((course) => (
                  <MenuItem key={course.id} value={course.id}>
                    {course.title}
                  </MenuItem>
                ))}
              </TextField>

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Add
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </Grid>
    </Page>
  );
};

export default Add;
