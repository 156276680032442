import { invokeApi } from "src/utils";
export const listInstructor = async () => {
  const requestObj = {
    path: `api/instructor/list_instructor`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
export const listActiveInstructor = async () => {
  const requestObj = {
    path: `api/instructor/list_active_instructors`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
export const delInstructor = async (id) => {
  const requestObj = {
    path: `api/instructor/delete_instructor/` + id,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
export const addInstructor = async (data) => {
  const requestObj = {
    path: `api/instructor/add_instructor`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const detailInstructor = async (id) => {
  const requestObj = {
    path: `api/instructor/detail_instructor/` + id,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const editInstructor = async (id, data) => {
  const requestObj = {
    path: `api/instructor/edit_instructor/` + id,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
