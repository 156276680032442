import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

// material
import {
  Stack,
  TextField,
  MenuItem,
  Typography,
  IconButton,
  Grid,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";

// component
import { Page } from "src/components";

import { Iconify } from "src/components";

import { addFaq } from "src/DAL/faq";
import { listActivePage } from "src/DAL/page";
const Add = () => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const [isLoading, setIsLoading] = useState(false);
  const [pages, setPages] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const [formInputs, setFormInputs] = useState({
    question_statment: "",
    answer_statment: "",
    status: "true",
    pages: [],
  });

  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // console.log(formInputs.gender);
    const data = new FormData();
    data.append("question_statment", formInputs.question_statment);
    data.append("answer_statment", formInputs.answer_statment);
    data.append(
      "page_id",
      JSON.stringify(
        Array.isArray(formInputs.pages) ? formInputs.pages : [formInputs.pages]
      )
    );
    // data.append("page_id",JSON.stringify( formInputs.page_id));
    data.append("status", formInputs.status);
    // const formDataArray = Array.from(data.entries());

    // console.log(formDataArray);
    // // console.log(data);
    const result = await addFaq(data);
    if (result.code === 200) {
      setIsLoading(false);
      navigate(-1);
      enqueueSnackbar("Added Successfully!", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const fetchActivePages = async () => {
    const response = await listActivePage();

    if (response.code === 200) {
      const pages = response.pages.map((page) => ({
        id: page._id,
        title: page.page_title,
      }));
      setPages(pages);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  useEffect(() => {
    fetchActivePages();
  }, []);
  useEffect(() => {
    if (pages.length > 0) {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        pages: [pages[0].id],
      }));
    }
  }, [pages]);
  return (
    <Page title="Add Faq">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ marginBottom: "40px", marginTop: "20px" }}
      >
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "40px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>

          <Typography variant="h4" align="center" sx={{ flex: 1 }}>
            Add Faq
          </Typography>
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Question Statement"
                  value={formInputs.question_statment}
                  onChange={handleChange}
                  name="question_statment"
                  required
                  multiline
                  rows={2}
                />

                <TextField
                  fullWidth
                  label="Answer Statement"
                  value={formInputs.answer_statment}
                  onChange={handleChange}
                  name="answer_statment"
                  required
                  multiline
                  rows={2}
                />
              </Stack>

              <TextField
                fullWidth
                label="Page"
                select
                value={formInputs.pages}
                onChange={handleChange}
                name="pages"
                required
                // SelectProps={{ multiple: true }}
              >
                {pages.map((page) => (
                  <MenuItem key={page.id} value={page.id}>
                    {page.title}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                fullWidth
                label="Status"
                select
                value={formInputs.status}
                onChange={handleChange}
                name="status"
                required
              >
                <MenuItem value="true">Active</MenuItem>
                <MenuItem value="false">Inactive</MenuItem>
              </TextField>

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Add
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </Grid>
    </Page>
  );
};

export default Add;
