import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Typography,
  IconButton,
  Grid,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { Page } from "src/components";
import { Iconify } from "src/components";

import { detailBatch, editBatch } from "src/DAL/batch";

import { listActiveCourse } from "src/DAL/course";

const Edit = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [courses, setCourses] = useState([]);
  const [formInputs, setFormInputs] = useState({
    name: "",

    status: "",
    order: "",
    courses: [],
  });

  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };

  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // console.log(formInputs.courses);
    const data = new FormData();
    data.append("name", formInputs.name);
    data.append("status", formInputs.status);
    data.append("order", formInputs.order);

    data.append("courses", JSON.stringify(formInputs.courses));

    // console.log(data.get("name"));
    // console.log(data.get("courses"));
    const result = await editBatch(id, data);
    if (result.code === 200) {
      setIsLoading(false);
      navigate("/batches");
      enqueueSnackbar("Edited Successfully!", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const fetchDetailBatch = async () => {
    const response = await detailBatch(id);
    // setCourses(data.batch.courses);
    if (response.code === 200) {
      // console.log("sss", data);
      // console.log(
      //   response.batch.courses.map((course) => course.title),
      //   "log"
      // );
      const courseIds = response.batch.courses.map((course) => course._id);

      setFormInputs({
        name: response.batch.name,
        status: response.batch.status,
        order: response.batch.order,
        courses: courseIds,
      });
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const fetchActiveCourses = async () => {
    const response = await listActiveCourse();

    if (response.code === 200) {
      const courses = response.courses.map((course) => ({
        id: course._id,
        title: course.title,
        description: course.description,
        duration: course.duration,
        image: course.image,
        status: course.status,
      }));
      setCourses(courses);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };
  useEffect(() => {
    fetchDetailBatch();
    fetchActiveCourses();
  }, []);

  return (
    <Page title="Edit Batch">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ marginBottom: "40px", marginTop: "20px" }}
      >
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "40px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>

          <Typography variant="h4" align="center" sx={{ flex: 1 }}>
            Edit Batch
          </Typography>
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Name"
                  value={formInputs.name}
                  onChange={handleChange}
                  name="name"
                  required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />

                <TextField
                  fullWidth
                  label="Status"
                  select
                  value={formInputs.status}
                  onChange={handleChange}
                  name="status"
                  required
                >
                  <MenuItem value="true">Active</MenuItem>
                  <MenuItem value="false">Inactive</MenuItem>
                </TextField>
              </Stack>
              <TextField
                fullWidth
                label="Order"
                value={formInputs.order}
                onChange={handleChange}
                name="order"
                required
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
              <TextField
                fullWidth
                label="Courses"
                select
                value={formInputs.courses}
                onChange={handleChange}
                name="courses"
                required
                SelectProps={{ multiple: true }}
              >
                {courses &&
                  courses.map((course) => (
                    <MenuItem key={course.id} value={course.id}>
                      {course.title}
                    </MenuItem>
                  ))}
              </TextField>

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Edit
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </Grid>
    </Page>
  );
};

export default Edit;
