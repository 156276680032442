import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  Typography,
  Grid,
  IconButton,
  Box,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { Page } from "src/components";

import { Iconify } from "src/components";
import { s3baseUrl } from "src/config/config";
import { delImage } from "src/DAL/delfiles";

import { detailTestimonial, editTestimonial } from "src/DAL/testimonial";
import { listActivePage } from "src/DAL/page";
const Edit = () => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const [image, setImage] = useState();
  const [pages, setPages] = useState([]);
  const [previewUrl, setPreviewUrl] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const [formInputs, setFormInputs] = useState({
    name: "",
    title: "",

    description: "",
    created_for: "",
    pages: [],
    button_text: "",
    button_link: "",
    status: "",
    image: "",
    order: "",
  });

  function handleFile(e) {
    // console.log(e.target.files);
    const file = e.target.files[0];
    // console.log(file);
    setImage(file);
    // console.log(image);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    handleFormSubmit(image);
    setImage("");
    // console.log(image);
    setShow(false);
  }
  const handleFormSubmit = async (img) => {
    // console.log("hello");
    const data = new FormData();
    data.append("path", img);
    const result = await delImage(data);
    if (result.code === 200) {
      console.log("Deleted");
      //handleLoading(index, true);

      // console.log(res,"--data")
    } else {
      console.log(`Error: ${result.status}`);
    }
  };

  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = new FormData();

    data.append("name", formInputs.name);
    data.append("title", formInputs.title);

    data.append("description", formInputs.description);
    data.append("created_for", formInputs.created_for);
    data.append(
      "page_id",
      JSON.stringify(
        Array.isArray(formInputs.pages) ? formInputs.pages : [formInputs.pages]
      )
    );
    data.append("button_text", formInputs.button_text);
    data.append("button_link", formInputs.button_link);
    data.append("image", image);
    data.append("status", formInputs.status);
    data.append("order", formInputs.order);
    // const formDataArray = Array.from(data.entries());

    // console.log(formDataArray);
    const result = await editTestimonial(id, data);
    if (result.code === 200) {
      setIsLoading(false);
      navigate(-1);
      enqueueSnackbar("Edited Successfully!", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getTestimonialDetail = async () => {
    const response = await detailTestimonial(id);
    if (response.code === 200) {
      const pageIds = response.Testimonial.page_id.map((page) => page._id);
      // console.log(data.instructor.first_name);
      setFormInputs({
        name: response.Testimonial.name,
        title: response.Testimonial.title,
        description: response.Testimonial.description,
        created_for: response.Testimonial.created_for,
        pages: pageIds,
        button_text: response.Testimonial.button_text,
        status: response.Testimonial.status,
        button_link: response.Testimonial.button_link,
        image: response.Testimonial.image,
        order: response.Testimonial.order,
      });
      if (response.Testimonial.image) {
        setImage(response.Testimonial.image);
        setShow(true);
      }
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const fetchActivePages = async () => {
    const response = await listActivePage();

    if (response.code === 200) {
      const pages = response.pages.map((page) => ({
        id: page._id,
        title: page.page_title,
      }));
      setPages(pages);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  useEffect(() => {
    getTestimonialDetail();
    fetchActivePages();
  }, []);
  return (
    <Page title="Edit Testimonial">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ marginBottom: "40px", marginTop: "20px" }}
      >
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "40px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>

          <Typography variant="h4" align="center" sx={{ flex: 1 }}>
            Edit Testimonial
          </Typography>
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Name"
                  value={formInputs.name}
                  onChange={handleChange}
                  name="name"
                  required
                />

                <TextField
                  fullWidth
                  label="Title"
                  value={formInputs.title}
                  onChange={handleChange}
                  name="title"
                  required
                />
              </Stack>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Created For"
                  value={formInputs.created_for}
                  onChange={handleChange}
                  name="created_for"
                  autoComplete="off"
                  required
                />
              </Stack>
              <TextField
                fullWidth
                label="Description"
                value={formInputs.description}
                onChange={handleChange}
                name="description"
                autoComplete="off"
                required
                multiline
                rows={2}
              />

              <TextField
                fullWidth
                label="Page"
                select
                value={formInputs.pages}
                onChange={handleChange}
                name="pages"
                required
                // SelectProps={{ multiple: true }}
              >
                {pages.map((page) => (
                  <MenuItem key={page.id} value={page.id}>
                    {page.title}
                  </MenuItem>
                ))}
              </TextField>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Button Text"
                  value={formInputs.button_text}
                  onChange={handleChange}
                  name="button_text"
                  autoComplete="off"
                  required
                />
                <TextField
                  fullWidth
                  label="Button Link"
                  value={formInputs.button_link}
                  onChange={handleChange}
                  name="button_link"
                  autoComplete="off"
                  required
                />
              </Stack>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Status"
                  select
                  value={formInputs.status}
                  onChange={handleChange}
                  name="status"
                  required
                >
                  <MenuItem value="true">Active</MenuItem>
                  <MenuItem value="false">Inactive</MenuItem>
                </TextField>
                <TextField
                  fullWidth
                  label="Order"
                  value={formInputs.order}
                  onChange={handleChange}
                  name="order"
                  required
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Stack>

              {show === false ? (
                <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                  <label htmlFor="image" className="form-label">
                    Image:
                  </label>
                  <Input
                    type="file"
                    id="image"
                    name="image"
                    accept="image/*"
                    onChange={handleFile}
                    required
                  />
                  {/* <Avatar alt={"image"} src={previewUrl} /> */}
                </Stack>
              ) : null}

              {show === true ? (
                <Stack
                  position="relative"
                  alignItems="center"
                  justifyContent="center"
                >
                  {" "}
                  <label htmlFor="image" className="form-label">
                    Image:
                  </label>
                  <Box
                    position="relative"
                    display="inline-block"
                    border="2px solid #ccc"
                    p={1}
                  >
                    {previewUrl ? (
                      <img
                        alt="image"
                        src={previewUrl}
                        width="150"
                        style={{ maxWidth: "100%" }}
                      />
                    ) : (
                      <img
                        alt="image"
                        src={s3baseUrl + formInputs.image}
                        width="150"
                        style={{ maxWidth: "100%" }}
                      />
                    )}
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleDeleteImage()}
                      style={{ position: "absolute", top: 0, right: 0 }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Box>
                </Stack>
              ) : null}

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Edit
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </Grid>
    </Page>
  );
};

export default Edit;
