import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { Page } from "src/components";

import { Iconify } from "src/components";
import { s3baseUrl } from "src/config/config";
import { detailStudent, editStudent } from "src/DAL/student";

import { listActiveBatch } from "src/DAL/batch";

const Edit = () => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const [image, setImage] = useState();
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [batchesId, setBatchesId] = useState([]);
  const [previewUrl, setPreviewUrl] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const [formInputs, setFormInputs] = useState({
    first_name: "",
    last_name: "",
    email: "",
    gender: "",
    courses: [],
    contact_number: "",
    fee_status: "",
    status: "",
    education: "",
    photo: "",
    address: "",
    batches: [],
  });

  function handleFile(e) {
    // console.log(e.target.files);
    const file = e.target.files[0];
    // console.log(file);
    setImage(file);
    // console.log(image);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    setImage("");
    // console.log(image);
    setShow(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(value, "kkkk");
    if (name === "batches") {
      // Find the selected batch object
      const selectedBatch = batches.find((batch) => batch.id === value);

      // Update the courses dropdown with the selected batch's courses array
      setCourses(selectedBatch.courses);
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
        courses: selectedBatch.courses.map((course) => course._id),
      }));
    } else {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }));
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log("iiikkkkkkkkkkkkkkkk", image);
    const data = new FormData();

    data.append("first_name", formInputs.first_name.trim());
    data.append("last_name", formInputs.last_name.trim());
    data.append("email", formInputs.email.trim());
    data.append("gender", formInputs.gender);
    data.append("courses", JSON.stringify(formInputs.courses));
    data.append(
      "batch_id",
      JSON.stringify(
        Array.isArray(formInputs.batches)
          ? formInputs.batches
          : [formInputs.batches]
      )
    );
    data.append("contact_number", formInputs.contact_number);
    data.append("education", formInputs.education);
    data.append("address", formInputs.address);
    data.append("profile_image", image ? image : "");
    data.append("fee_status", formInputs.fee_status);
    data.append("status", formInputs.status);
    // console.log(data.get("image"), "FroData ImageImageImageImageImage");
    // console.log(image, "state ImageImageImageImageImage");
    const result = await editStudent(id, data);
    if (result.code === 200) {
      setIsLoading(false);
      navigate("/students");
      enqueueSnackbar("Edited Successfully!", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  async function fetchStudentDetail() {
    const response = await detailStudent(id);
    if (response.code === 200) {
      // setCourses(response.student.courses);
      const courseIds = response.student.courses.map((course) => course._id);

      const batchIds = response.student.batch_id.map((batch) => batch._id);
      console.log(batchIds, "batchIdsbatchIds");

      // console.log(data.student.first_name);
      // const batchIdt = batchIds.join("");
      // const batchId = batchIds[0];

      setFormInputs({
        first_name: response.student.first_name,
        last_name: response.student.last_name,
        email: response.student.email,
        gender: response.student.gender,
        address: response.student.address,
        contact_number: response.student.contact_number,
        fee_status: response.student.fee_status,
        status: response.student.status,
        education: response.student.education,
        image: response.student.profile_image,
        courses: courseIds,
        batches: batchIds,
      });

      if (response.student.profile_image) {
        setImage(response.student.profile_image);
        setShow(true);
      }
      if (batchIds && batchIds.length > 0) {
        fetchActiveBatches(batchIds);
      }
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  }

  const fetchActiveBatches = async (batchIds) => {
    const response = await listActiveBatch();

    if (response.code === 200) {
      const batchesList = response.batches.map((batch) => ({
        id: batch._id,
        name: batch.name,
        student_count: batch.student_count,
        status: batch.status,
        courses: batch.courses,
      }));
      // console.log(batchesList, "jjjjj");

      // console.log(batchIds[0], "kkkkkkkkk");

      const selectedBatchIndex = batchesList.findIndex(
        (batch) => batch.id === batchIds[0]
      );

      // console.log(selectedBatchIndex, "selectedBatchselectedBatch");
      // console.log("cccc");
      setCourses(batchesList[selectedBatchIndex].courses);

      setBatches(batchesList);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  useEffect(() => {
    fetchStudentDetail();
  }, []);

  return (
    <Page title="Edit Student">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ marginBottom: "40px", marginTop: "20px" }}
      >
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "40px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>

          <Typography variant="h4" align="center" sx={{ flex: 1 }}>
            Edit Student
          </Typography>
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="First name"
                  value={formInputs.first_name}
                  onChange={handleChange}
                  name="first_name"
                  required
                  inputProps={{
                    pattern: "[A-Za-z]{3,}",
                    title:
                      "Please enter at least 3 letters with only alphabetical characters.",
                  }}
                />

                <TextField
                  fullWidth
                  label="Last name"
                  value={formInputs.last_name}
                  onChange={handleChange}
                  name="last_name"
                  required
                  inputProps={{
                    pattern: "[A-Za-z]{3,}",
                    title:
                      "Please enter at least 3 letters with only alphabetical characters.",
                  }}
                />
              </Stack>

              <TextField
                fullWidth
                type="email"
                label="Email address"
                value={formInputs.email}
                onChange={handleChange}
                name="email"
                autoComplete="off"
                required
                inputProps={{
                  pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$",
                  title: "Please enter a valid email address",
                }}
              />
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Gender"
                  select
                  required
                  value={formInputs.gender}
                  onChange={handleChange}
                  name="gender"
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                </TextField>

                <TextField
                  fullWidth
                  label="Fee Status"
                  select
                  value={formInputs.fee_status}
                  onChange={handleChange}
                  name="fee_status"
                  required
                >
                  <MenuItem value="paid">Paid</MenuItem>
                  <MenuItem value="due">Due</MenuItem>
                  <MenuItem value="free">Full Fee Scholarship</MenuItem>
                </TextField>
              </Stack>

              <TextField
                fullWidth
                label="Batch"
                select
                value={formInputs.batches}
                onChange={handleChange}
                name="batches"
                required
                // SelectProps={{ multiple: true }}
              >
                {batches.map((batch) => (
                  <MenuItem key={batch.id} value={batch.id}>
                    {batch.name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                label="Courses"
                select
                value={formInputs.courses}
                onChange={handleChange}
                name="courses"
                required
                SelectProps={{ multiple: true }}
              >
                {courses.map((course) => (
                  <MenuItem key={course._id} value={course._id}>
                    {course.title}
                  </MenuItem>
                ))}
              </TextField>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Phone Number"
                  value={formInputs.contact_number}
                  onChange={handleChange}
                  name="contact_number"
                  required
                  inputProps={{
                    pattern: "[0-9]{11}",
                    title:
                      "Please enter a valid phone number in the format XXXXXXXXXXX",
                  }}
                />
                <TextField
                  fullWidth
                  label="Status"
                  select
                  value={formInputs.status}
                  onChange={handleChange}
                  name="status"
                  required
                >
                  <MenuItem value="true">Active</MenuItem>
                  <MenuItem value="false">Inactive</MenuItem>
                </TextField>
              </Stack>
              <TextField
                fullWidth
                label="Education"
                value={formInputs.education}
                onChange={handleChange}
                name="education"
                required
              />
              <TextField
                fullWidth
                label="Address"
                value={formInputs.address}
                onChange={handleChange}
                name="address"
                required
              />
              {show === false ? (
                <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                  <label htmlFor="image" className="form-label">
                    Image:
                  </label>
                  <Input
                    type="file"
                    id="image"
                    name="image"
                    accept="image/*"
                    onChange={handleFile}
                    required
                  />
                  {/* <Avatar alt={"image"} src={previewUrl} /> */}
                </Stack>
              ) : null}

              {show === true ? (
                <Stack
                  position="relative"
                  alignItems="center"
                  justifyContent="center"
                >
                  {" "}
                  <label htmlFor="image" className="form-label">
                    Image:
                  </label>
                  <Box
                    position="relative"
                    display="inline-block"
                    border="2px solid #ccc"
                    p={1}
                  >
                    {previewUrl ? (
                      <img
                        alt="image"
                        src={previewUrl}
                        width="150"
                        style={{ maxWidth: "100%" }}
                      />
                    ) : (
                      <img
                        alt="image"
                        src={s3baseUrl + formInputs.image}
                        width="150"
                        style={{ maxWidth: "100%" }}
                      />
                    )}
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleDeleteImage()}
                      style={{ position: "absolute", top: 0, right: 0 }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Box>
                </Stack>
              ) : null}

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Edit
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </Grid>
    </Page>
  );
};

export default Edit;
