import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
} from "@mui/material";
 
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";

import { listContact } from "src/DAL/contact";
// component
import { Page } from "src/components";
import { Iconify } from "src/components";
import { addStudent } from "src/DAL/student";
 
const Contact = () => {
  const [courses, setCourses] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const { enqueueSnackbar } = useSnackbar();
  const [formInputs, setFormInputs] = useState({
    first_name: "",
    last_name: "",
    email: "",

    courses: [],
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const data = new FormData();
    data.append("first_name", formInputs.first_name.trim());
    data.append("last_name", formInputs.last_name.trim());
    data.append("email", formInputs.email.trim());

    data.append("courses", JSON.stringify(formInputs.courses));

    data.append("phone_number", formInputs.phone_number);

    // console.log(formDataArray);
    const result = await addStudent(data);
    if (result.code === 200) {
      setIsLoading(false);
      //   navigate("/students");
      enqueueSnackbar("Added Successfully!", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  return (
    <Page title="Contact US">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ marginBottom: "40px", marginTop: "20px" }}
      >
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "40px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>

          <Typography variant="h4" align="center" sx={{ flex: 1 }}>
            Contact Us
          </Typography>
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="First name"
                  value={formInputs.first_name}
                  onChange={handleChange}
                  name="first_name"
                  required
                  inputProps={{
                    pattern: "[A-Za-z]{3,}",
                    title:
                      "Please enter at least 3 letters with only alphabetical characters.",
                  }}
                />

                <TextField
                  fullWidth
                  label="Last name"
                  value={formInputs.last_name}
                  onChange={handleChange}
                  name="last_name"
                  required
                  inputProps={{
                    pattern: "[A-Za-z]{3,}",
                    title:
                      "Please enter at least 3 letters with only alphabetical characters.",
                  }}
                />
              </Stack>

              <TextField
                fullWidth
                type="email"
                label="Email address"
                value={formInputs.email}
                onChange={handleChange}
                name="email"
                autoComplete="off"
                required
                inputProps={{
                  pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$",
                  title: "Please enter a valid email address",
                }}
              />

              <TextField
                fullWidth
                label="Courses"
                select
                value={formInputs.courses}
                onChange={handleChange}
                name="courses"
                required
                SelectProps={{ multiple: true }}
              >
                {courses.map((course) => (
                  <MenuItem key={course._id} value={course._id}>
                    {course.title}
                  </MenuItem>
                ))}
              </TextField>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Phone Number"
                  value={formInputs.phone_number}
                  required
                  onChange={handleChange}
                  name="phone_number"
                  inputProps={{
                    pattern: "[0-9]{11}",
                    title:
                      "Please enter a valid phone number in the format XXXXXXXXXXX",
                  }}
                />
              </Stack>

              <Stack>
                <TextField
                  id="outlined-multiline-static"
                  label="Message"
                  multiline
                  rows={4}
                  //   defaultValue="Enter Your Message Here...."
                />
              </Stack>

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Send
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </Grid>
    </Page>
  );
};

export default Contact;
