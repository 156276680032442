import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { Page } from "src/components";
import { Iconify } from "src/components";
import { addStudent } from "src/DAL/student";

import { listActiveBatch } from "src/DAL/batch";
const Add = () => {
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState();
  const [show, setShow] = useState(false);
  const [previewUrl, setPreviewUrl] = useState();

  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const { enqueueSnackbar } = useSnackbar();
  const [formInputs, setFormInputs] = useState({
    first_name: "",
    last_name: "",
    email: "",
    gender: "male",
    courses: [],
    phone: "",
    fee_status: "paid",
    status: "true",
    education: "",
    image: "",
    address: "",
    batches: [],
  });

  function handleFile(e) {
    const file = e.target.files[0];

    setImage(file);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    setShow(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(value, "kkkk");
    if (name === "batches") {
      // Find the selected batch object
      const selectedBatch = batches.find((batch) => batch.id === value);

      // Update the courses dropdown with the selected batch's courses array
      setCourses(selectedBatch.courses);
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
        courses: selectedBatch.courses.map((course) => course._id),
      }));
    } else {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }));
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // console.log("immmmm", image);
    // console.log(
    //   Array.isArray(formInputs.batches)
    //     ? formInputs.batches
    //     : [formInputs.batches],
    //   "batchesconvertedd"
    // );

    // console.log(formInputs.batches, "batches");
    // console.log(formInputs.courses, "courses");
    const data = new FormData();
    data.append("first_name", formInputs.first_name.trim());
    data.append("last_name", formInputs.last_name.trim());
    data.append("email", formInputs.email.trim());
    data.append("gender", formInputs.gender);
    data.append("courses", JSON.stringify(formInputs.courses));
    data.append(
      "batch_id",
      JSON.stringify(
        Array.isArray(formInputs.batches)
          ? formInputs.batches
          : [formInputs.batches]
      )
    );
    data.append("contact_number", formInputs.phone_number);
    data.append("education", formInputs.education);
    data.append("address", formInputs.address);
    data.append("profile_image", image);
    data.append("fee_status", formInputs.fee_status);
    data.append("status", formInputs.status);
    // const formDataArray = Array.from(data.entries());

    // console.log(formDataArray);
    const result = await addStudent(data);
    if (result.code === 200) {
      setIsLoading(false);
      navigate("/students");
      enqueueSnackbar("Added Successfully!", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (courses.length > 0) {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        courses: [courses[0]._id],
      }));
    }
  }, [courses]);
  useEffect(() => {
    if (batches.length > 0) {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        batches: [batches[0].id],
      }));
    }
  }, [batches]);

  const fetchActiveBatches = async () => {
    const response = await listActiveBatch();

    if (response.code === 200) {
      const batches = response.batches.map((batch) => ({
        id: batch._id,
        name: batch.name,
        student_count: batch.student_count,
        status: batch.status,
        courses: batch.courses,
      }));
      setCourses(batches[0].courses);
      setBatches(batches);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  useEffect(() => {
    fetchActiveBatches();
  }, []);

  return (
    <Page title="Add Student">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ marginBottom: "40px", marginTop: "20px" }}
      >
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "40px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>

          <Typography variant="h4" align="center" sx={{ flex: 1 }}>
            Add Student
          </Typography>
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="First name"
                  value={formInputs.first_name}
                  onChange={handleChange}
                  name="first_name"
                  required
                  inputProps={{
                    pattern: "[A-Za-z]{3,}",
                    title:
                      "Please enter at least 3 letters with only alphabetical characters.",
                  }}
                />

                <TextField
                  fullWidth
                  label="Last name"
                  value={formInputs.last_name}
                  onChange={handleChange}
                  name="last_name"
                  required
                  inputProps={{
                    pattern: "[A-Za-z]{3,}",
                    title:
                      "Please enter at least 3 letters with only alphabetical characters.",
                  }}
                />
              </Stack>

              <TextField
                fullWidth
                type="email"
                label="Email address"
                value={formInputs.email}
                onChange={handleChange}
                name="email"
                autoComplete="off"
                required
                inputProps={{
                  pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$",
                  title: "Please enter a valid email address",
                }}
              />
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Gender"
                  select
                  required
                  value={formInputs.gender}
                  onChange={handleChange}
                  name="gender"
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                </TextField>

                <TextField
                  fullWidth
                  label="Fee Status"
                  select
                  value={formInputs.fee_status}
                  onChange={handleChange}
                  name="fee_status"
                  required
                >
                  <MenuItem value="paid">Paid</MenuItem>
                  <MenuItem value="due">Due</MenuItem>
                  <MenuItem value="free">Full Fee Scholarship</MenuItem>
                </TextField>
              </Stack>

              <TextField
                fullWidth
                label="Batch"
                select
                value={formInputs.batches}
                onChange={handleChange}
                name="batches"
                required
                // SelectProps={{ multiple: true }}
              >
                {batches.map((batch) => (
                  <MenuItem key={batch.id} value={batch.id}>
                    {batch.name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                label="Courses"
                select
                value={formInputs.courses}
                onChange={handleChange}
                name="courses"
                required
                SelectProps={{ multiple: true }}
              >
                {courses.map((course) => (
                  <MenuItem key={course._id} value={course._id}>
                    {course.title}
                  </MenuItem>
                ))}
              </TextField>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Phone Number"
                  value={formInputs.phone_number}
                  required
                  onChange={handleChange}
                  name="phone_number"
                  inputProps={{
                    pattern: "[0-9]{11}",
                    title:
                      "Please enter a valid phone number in the format XXXXXXXXXXX",
                  }}
                />
                <TextField
                  fullWidth
                  label="Status"
                  select
                  value={formInputs.status}
                  onChange={handleChange}
                  name="status"
                  required
                >
                  <MenuItem value="true">Active</MenuItem>
                  <MenuItem value="false">Inactive</MenuItem>
                </TextField>
              </Stack>
              <TextField
                fullWidth
                label="Education"
                required
                value={formInputs.education}
                onChange={handleChange}
                name="education"
              />
              <TextField
                fullWidth
                label="Address"
                required
                value={formInputs.address}
                onChange={handleChange}
                name="address"
              />
              {show === false ? (
                <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                  <label htmlFor="image" className="form-label">
                    Image:
                  </label>
                  <Input
                    type="file"
                    id="image"
                    name="image"
                    accept="image/*"
                    onChange={handleFile}
                    required
                  />
                  {/* <Avatar alt={"image"} src={previewUrl} /> */}
                </Stack>
              ) : null}

              {show === true ? (
                <Stack
                  position="relative"
                  alignItems="center"
                  justifyContent="center"
                >
                  {" "}
                  <label htmlFor="image" className="form-label">
                    Image:
                  </label>
                  <Box
                    position="relative"
                    display="inline-block"
                    border="2px solid #ccc"
                    p={1}
                  >
                    <img
                      alt="image"
                      src={previewUrl}
                      width="150"
                      style={{ maxWidth: "100%" }}
                    />
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleDeleteImage()}
                      style={{ position: "absolute", top: 0, right: 0 }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Box>
                </Stack>
              ) : null}

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Add
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </Grid>
    </Page>
  );
};

export default Add;
