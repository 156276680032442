import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useParams } from "react-router-dom";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  Typography,
  Grid,
  IconButton,
  Box,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { Page, CircularLoader } from "src/components";

import { Iconify } from "src/components";
import { s3baseUrl } from "src/config/config";
import { delImage } from "src/DAL/delfiles";
import TinyEditor from "src/components/Ckeditor";
import { detailTestimonial, editTestimonial } from "src/DAL/testimonial";
import { UpdatePageDetail, listActivePage } from "src/DAL/page";
import { detailTemplate } from "src/DAL/template";
import { addImage, addImageWithSize } from "src/DAL/uploadfiles";
import { handleImageExtensions } from "src/constant";

const Edit = () => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const [file, setProfileImage] = useState();
  const [images, setImages] = useState([]);
  const [previews, setPreviews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [shortDescriptionCk, setShortDescriptionCk] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const { id } = useParams();

  const location = useLocation();
  const rowData = location.state;
  const [inputs, setInputs] = useState({});
  const [templateFieldsData, setTemplateFieldsData] = useState([]);

  const [formInputs, setFormInputs] = useState({
    template_name: "",
    template_status: "",
    attributes: [],
  });

  const handleRemove = () => {
    setPreviews("");
    setImage("");
  };

  const fileChangedHandler = async (e, i) => {
    // setIsLoading(true);
    console.log(e, i, "formData");

    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("width", i.width);
    formData.append("height", i.height);
    let allowedImageExtension = i.image_extension;
    let uploadedImageExtension = e.target.files[0]?.name?.split(".")[1];
    let extension_matched = allowedImageExtension.includes(
      "." + uploadedImageExtension
    );
    console.log(...formData, "formData");
    if (extension_matched) {
      const imageUpload = await addImageWithSize(formData);
      if (imageUpload.code == 200) {
        setInputs({
          ...inputs,
          [e.target.name]: imageUpload.data.path,
        });
        setIsLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    } else {
      enqueueSnackbar(
        `Upload images with mentioned ${handleImageExtensions(
          allowedImageExtension
        )} extension`,
        {
          variant: "error",
        }
      );
    }
  };
  const handleChange = (event, i) => {
    console.log(event, "eveet");
    const name = event.target.name;
    const value = event.target.value;
    setTemplateFieldsData((values) => ({ ...values, [name]: value }));
  };

  const handleChangeSet = (event, i) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangeEditor = (event, i) => {
    console.log(event, "eventttttt");
    console.log(i, "valueeeee");
    const name = event;
    const value = i;
    setTemplateFieldsData((values) => ({ ...values, [name]: value }));
  };

  const handleChangeSetEditor = (event, i) => {
    const name = event;
    const value = i;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const fileChangedHandlerUpdate = async (e, i) => {
    // setIsLoading(true);

    const name = e.target.name;
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("width", i.width);
    formData.append("height", i.height);
    let allowedImageExtension = i.image_extension;
    let uploadedImageExtension = e.target.files[0]?.name?.split(".")[1];
    let extension_matched = allowedImageExtension.includes(
      "." + uploadedImageExtension
    );
    console.log(
      extension_matched,
      uploadedImageExtension,
      e.target.files[0],
      "extension_matched"
    );
    if (extension_matched == true) {
      const imageUpload = await addImageWithSize(formData);
      if (imageUpload.code == 200) {
        setTemplateFieldsData((prevState) => ({
          ...prevState,
          [name]: imageUpload.data.path,
        }));
        setIsLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    } else {
      enqueueSnackbar(
        `Upload images with mentioned ${handleImageExtensions(
          allowedImageExtension
        )} extension`,
        {
          variant: "error",
        }
      );
    }
  };
  const handleFormSubmit = async (img) => {
    // console.log("hello");
    const data = new FormData();
    data.append("path", img);
    const result = await delImage(data);
    if (result.code === 200) {
      console.log("Deleted");
      //handleLoading(index, true);

      // console.log(res,"--data")
    } else {
      console.log(`Error: ${result.status}`);
    }
  };

  // const handleChange = (e) => {
  //   const { target } = e;
  //   setFormInputs({ ...formInputs, [target.name]: target.value });
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(inputs, "adddddddddd");
    console.log(templateFieldsData, "edittttttt");
    console.log(formInputs, "fieldssss");
    const data = {
      page_detail:
        templateFieldsData !== undefined ? templateFieldsData : inputs,
    };
    // formInputs.attributes.map((field, index) => {

    // }

    // if (shortDescriptionCk.length < 1) {
    //   return enqueueSnackbar("Discription can not be empty !", {
    //     variant: "error",
    //   });
    // }
    setIsLoading(true);

    const result = await UpdatePageDetail(
      location.state.rowData.page_title_slug,
      data
    );
    if (result.code === 200) {
      setIsLoading(false);
      navigate(-1);
      enqueueSnackbar("Edited Successfully!", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getTemplateDetail = async () => {
    const response = await detailTemplate(id);
    if (response.code === 200) {
      setIsLoading(false);
      // console.log(data.instructor.first_name)
      setTemplateFieldsData(location.state.rowData.page_detail);
      // setTemplateFieldsData(response?.Template?.template_attributes_info);
      setFormInputs({
        template_name: response.Templete.template_name,
        template_status: response.Templete.template_status,
        attributes: response.Templete.template_attributes_info,
      });
      // setPreviews(
      //   s3baseUrl + response.Templete.template_attributes_info.attribute_db_name
      // );
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  console.log(inputs, "inputs");
  console.log("i am herere", location.state.rowData.page_detail);
  useEffect(() => {
    getTemplateDetail();
  }, []);
  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Update Page">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ marginBottom: "40px", marginTop: "20px" }}
      >
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "40px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>

          <Typography variant="h4" align="center" sx={{ flex: 1 }}>
            Update Page
          </Typography>
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
              <div className="row">
                {formInputs.attributes.map((field, index) => {
                  if (field.attribute_type === "file") {
                    return (
                      <div className="col-sm-12 col-md-6  mt-3">
                        <div className="row w-100 mb-3 mt-4" key={index}>
                          <p>
                            {field.attribute_label}
                            {field.required == true ? " *" : ""}
                          </p>

                          <div className="col-md-6 mt-2 mb-2 d-flex">
                            {/* {previews && (
                <div>
                  {previews.map((preview, index) => (
                    <span className="preview mt-2" key={index}>
                      <span onClick={() => handleRemove(index)}>x</span>
                      <img
                        src={preview}
                        height={50}
                        width="100%"
                        alt={`Preview ${index}`}
                      />
                    </span>
                  ))}
                </div>
              )} */}
                            {templateFieldsData !== undefined ? (
                              <img
                                src={
                                  templateFieldsData[
                                    field?.attribute_db_name
                                  ] == "" ||
                                  templateFieldsData[
                                    field?.attribute_db_name
                                  ] == null
                                    ? ""
                                    : s3baseUrl +
                                      templateFieldsData[
                                        field?.attribute_db_name
                                      ]
                                }
                                height="50"
                              />
                            ) : inputs[field.attribute_db_name] !==
                              undefined ? (
                              <img
                                src={
                                  s3baseUrl + inputs[field.attribute_db_name]
                                }
                                height="50"
                              />
                            ) : (
                              ""
                            )}

                            <span className="upload-button mt-2 ms-4">
                              <input
                                color="primary"
                                accept="image/*"
                                type="file"
                                // required={field.required == true ? true : false}
                                name={field.attribute_db_name}
                                id={field.attribute_db_name}
                                style={{ display: "none" }}
                                onChange={
                                  templateFieldsData == undefined
                                    ? (event) =>
                                        fileChangedHandler(event, field)
                                    : (event) =>
                                        fileChangedHandlerUpdate(event, field)
                                }
                              />
                              <label htmlFor={field.attribute_db_name}>
                                {/* <CloudUploadIcon /> */}
                                <Button
                                  className="small-contained-button"
                                  startIcon={<FileUploadIcon />}
                                  component="span"
                                  variant="outlined"
                                  size="small"
                                >
                                  Upload
                                </Button>
                              </label>
                            </span>
                          </div>

                          <hr />
                          <FormHelperText className="pt-0">
                            (Recommended Size {field.width}*{field.height})
                            ("JPG", "JPEG", "PNG","WEBP", GIF)
                          </FormHelperText>
                        </div>
                        {/* <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                          <div className="row w-100 div-style ms-0 pt-0">
                            <div className="col-4">
                              <p className="">Upload Image *</p>
                              <FormHelperText className="pt-0">
                                Image Size(1000 X 670) ("JPG", "JPEG",
                                "PNG","WEBP")
                              </FormHelperText>
                            </div>
                            <div className="col-2">
                              {file && <img src={file} height={50} />}
                            </div>
                            <div className="col-6 text-end pt-2">
                              <label htmlFor="contained-button-file">
                                <Input
                                  accept="image/*"
                                  id="contained-button-file"
                                  multiple
                                  type="file"
                                  name="image"
                                  onChange={fileChangedHandler}
                                />
                                <Button
                                  className="small-contained-button"
                                  startIcon={<FileUploadIcon />}
                                  component="span"
                                >
                                  Upload
                                </Button>
                              </label>
                            </div>
                          </div>
                          {inputs.image.name == "" ? (
                            ""
                          ) : (
                            <p className="text-secondary">
                              {inputs.image.name}
                            </p>
                          )}
                        </div> */}
                      </div>
                    );
                  } else if (field.attribute_type === "input") {
                    return (
                      // <Grid container spacing={2} key={index}>
                      //   <Grid item xs={12} sm={6}>
                      <div className="col-sm-12 col-md-6  mt-3">
                        <TextField
                          id="outlined-basic"
                          label={field?.attribute_label}
                          variant="outlined"
                          fullWidth
                          required={field.required == true ? true : false}
                          name={field?.attribute_db_name}
                          value={
                            templateFieldsData !== undefined
                              ? templateFieldsData[field?.attribute_db_name]
                              : inputs.field?.attribute_db_name
                          }
                          onChange={
                            templateFieldsData == undefined
                              ? (e) => handleChangeSet(e, field)
                              : (e) => handleChange(e, field)
                          }
                        />
                      </div>

                      //   </Grid>
                      // </Grid>
                    );
                  } else if (field.attribute_type === "editor") {
                    return (
                      <div className="col-12 mt-4">
                        <h6>
                          {field.attribute_label}
                          {field.required == true ? " *" : ""}
                        </h6>
                        <TinyEditor
                          setDetailDescription={
                            templateFieldsData == undefined
                              ? (value) =>
                                  handleChangeSetEditor(
                                    field?.attribute_db_name,
                                    value
                                  )
                              : (value) =>
                                  handleChangeEditor(
                                    field?.attribute_db_name,
                                    value
                                  )
                          }
                          detailDescriptionCk={
                            templateFieldsData !== undefined
                              ? templateFieldsData[field?.attribute_db_name]
                              : inputs.field?.attribute_db_name
                          }
                        />
                      </div>
                    );
                  }
                })}
              </div>
              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Save
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </Grid>
    </Page>
  );
};

export default Edit;
