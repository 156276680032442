import * as Yup from "yup";
import { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import {
  Link,
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  FormControl,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// component
import Iconify from "../../../components/Iconify";
import { useSnackbar } from "notistack";
import { login } from "src/DAL/auth";

// ----------------------------------------------------------------------
import { useAppContext } from "src/hooks";

export default function LoginForm() {
  const { _set_user_profile } = useAppContext();

  const navigate = useNavigate();
  const [formInputs, setFormInputs] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const obj = {
      email: formInputs.email.trim(),
      password: formInputs.password.trim(),
      type: 0,
    };

    // const data = new FormData();
    // console.log(formInputs.email.trim());
    // data.append("email", formInputs.email.trim());
    // data.append("password", formInputs.password.trim());
    // data.append("type", 0);

    const result = await login(obj);
    if (result.code === 200) {
      localStorage.setItem("token", result.token);
      const user = {
        first_name: result.user.first_name,
        last_name: result.user.last_name,
        email: result.user.user_id.email,
        image: result.user.profile_image,
      };

      console.log(JSON.stringify(user));
      localStorage.setItem("user", JSON.stringify(user));
      const storedObjectString = localStorage.getItem("user");
      const storedObject = JSON.parse(storedObjectString);
      _set_user_profile(storedObject);
      navigate("/dashboard");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <Stack spacing={3}>
        <TextField
          value={formInputs.email}
          onChange={handleChange}
          name="email"
          required
          fullWidth
          autoComplete="username"
          type="email"
          label="Email address"
        />

        <TextField
          value={formInputs.password}
          onChange={handleChange}
          fullWidth
          name="password"
          required
          autoComplete="current-password"
          type={showPassword ? "text" : "password"}
          label="Password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleShowPassword} edge="end">
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 2 }}
      >
        {/* <Link
          component={RouterLink}
          variant="subtitle2"
          to="/forgot-password"
          underline="hover"
        >
          Forgot password?
        </Link> */}
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isLoading}
      >
        Login
      </LoadingButton>
    </form>
  );
}
