import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import React from "react";
// import { get_root_value } from "src/utils/domUtils";
// import { get_root_value } from "src/utils/domUtils";

function DelPopup({ title, message, onOk, open, setOpen }) {
  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            boxShadow: "none",
          },
        }}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogTitle>{message}</DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={onOk}>Agree</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DelPopup;
