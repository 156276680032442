// component
import Iconify from "../../components/Iconify";

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: "dashboard",
    path: "/dashboard",
    icon: getIcon("eva:pie-chart-2-fill"),
    /* "children" key can be added in these objects, example:children:[{...}] */
  },
  // {
  //   title: "users",
  //   path: "/users",
  //   icon: getIcon("fe:users"),
  // },
  {
    title: "Batches",
    path: "/batches",
    icon: getIcon("ph:student-bold"),
  },
  {
    title: "Courses",
    path: "/courses",
    icon: getIcon("fe:book"),
  },
  {
    title: "Instructors",
    path: "/instructors",
    icon: getIcon("mdi:teacher"),
  },
  {
    title: "Students",
    path: "/students",
    icon: getIcon("fe:users"),
  },

  {
    title: "Contact Us",
    path: "/contactlist",
    icon: getIcon("fe:phone"),
  },

  {
    title: "Manage Website",
    // icon: getIcon("radix-icons:dropdown-menu"),
    icon: getIcon("material-symbols:settings"),
    // openIcon: getIcon("material-symbols:fitbit-arrow-downward"),
    // closeIcon: getIcon("material-symbols:fitbit-arrow-upward"),

    children: [
      {
        title: "Pages",
        path: "/pages",
        // icon: getIcon("material-symbols:fitbit-arrow-downward"),
        // icon: getIcon("material-symbols:fitbit-arrow-upward"),
      },
      // {
      //   title: "Section",
      //   path: "/section",
      // },
    ],
  },

  /* example: collapsible sidebar routes
    {
      title: "parent",
      path: "/parent",
      icon: getIcon("fe:users"),
    children: [
        {
            title: "child1",
        path: "/parent/child1",
        icon: getIcon("fe:users"),
      },
      {
        title: "child2",
        path: "/parent/child2",
        icon: getIcon("fe:users"),
      },
    ],
  },
  */
];

export default navConfig;
