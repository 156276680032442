import { invokeApi } from "src/utils";
export const listCourse = async () => {
  const requestObj = {
    path: `api/course/list_course`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
export const listActiveCourse = async () => {
  const requestObj = {
    path: `api/course/list_active_courses`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
export const delCourse = async (id) => {
  const requestObj = {
    path: `api/course/delete_course/` + id,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
export const addCourse = async (data) => {
  const requestObj = {
    path: `api/course/add_course`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const detailCourse = async (id) => {
  const requestObj = {
    path: `api/course/detail_course/` + id,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const editCourse = async (id, data) => {
  const requestObj = {
    path: `api/course/edit_course/` + id,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
