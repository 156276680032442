import { invokeApi } from "src/utils";
export const listBatch = async () => {
  const requestObj = {
    path: `api/batch/list_batch`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
export const listActiveBatch = async () => {
  const requestObj = {
    path: `api/batch/active_batch_list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
export const delBatch = async (id) => {
  const requestObj = {
    path: `api/batch/delete_batch/` + id,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
export const addBatch = async (data) => {
  const requestObj = {
    path: `api/batch/add_batch`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const detailBatch = async (id) => {
  const requestObj = {
    path: `api/batch/detail_batch/` + id,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const editBatch = async (id, data) => {
  const requestObj = {
    path: `api/batch/edit_batch/` + id,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
