import { invokeApi } from "src/utils";
export const listContact = async () => {
  const requestObj = {
    path: `api/app_api/list_contact`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};