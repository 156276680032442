// @mui
import { useTheme } from "@mui/material/styles";
import { Grid, Container, Typography } from "@mui/material";
// components
import SummaryCard from "./components/SummaryCard";
import { CircularLoader, Page } from "src/components";
//hooks
import { useAppContext } from "src/hooks";
import { useEffect, useState } from "react";
import { dashboard } from "src/DAL/dashboard";

// ----------------------------------------------------------------------

export default function Dashboard() {
  const theme = useTheme();
  const { _get_user_profile } = useAppContext();
  const [dashboardData, setDashboardData] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const DASHBOARD_DATA = [
    {
      color: "success",
      title: "Batches",
      count: dashboardData,
      icon: "ph:student",
    },
    {
      color: "info",
      title: "Courses",
      count: dashboardData,
      icon: "fe:book",
    },
    {
      color: "secondary",
      title: "Instructors",
      count: dashboardData,
      icon: "mdi:teacher",
    },
    {
      color: "primary",
      title: "Students",
      count: dashboardData,
      icon: "fe:users",
    },
  ];

  const updatedData = DASHBOARD_DATA.map((item) => {
    if (item.title === "Students") {
      return {
        ...item,
        count: dashboardData.total_students,
      };
    } else if (item.title === "Batches") {
      return {
        ...item,
        count: dashboardData.total_batches,
      };
    } else if (item.title === "Courses") {
      return {
        ...item,
        count: dashboardData.total_courses,
      };
    } else if (item.title === "Instructors") {
      return {
        ...item,
        count: dashboardData.total_instructors,
      };
    }

    return item;
  });

  const fetchDashboard = async () => {
    const resp = await dashboard();
    if (resp.code === 200) {
      setIsLoading(false);
      setDashboardData(resp.dashboard);
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };

  useEffect(() => {
    fetchDashboard();
  }, []);
  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          {updatedData.map((item, i) => (
            <Grid key={i} item xs={12} sm={6} md={3}>
              <SummaryCard
                color={item.color}
                title={item.title}
                count={item.count}
                icon={item.icon}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Page>
  );
}
