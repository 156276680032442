import React from "react";

import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

// material
import {
  Stack,
  TextField,
  Avatar,
  Input,
  IconButton,
  Typography,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import ClearIcon from "@mui/icons-material/Clear";
import { useTheme } from "@mui/material/styles";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
// component
import { CircularLoader, Page } from "src/components";
import { Iconify } from "src/components";
import { s3baseUrl } from "src/config/config";
import { delImage } from "src/DAL/delfiles";
import { detailAdmin, editAdmin } from "src/DAL/auth";
import { addImage } from "src/DAL/uploadfiles";
import { useAppContext } from "src/hooks";
import { Box } from "@mui/system";

const Edit = () => {
  const theme = useTheme();
  const { _set_user_profile } = useAppContext();
  const [image, setImage] = useState();
  const [previewUrl, setPreviewUrl] = useState();
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [show, setShow] = useState(false);
  const [admin, setAdmin] = useState();
  const [path, setPath] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const [formInputs, setFormInputs] = useState({
    first_name: "",
    profile_image: "",
    last_name: "",
  });

  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };

  function handleFile(e) {
    // console.log(e.target.files);
    const file = e.target.files[0];
    // console.log(file);
    setImage(file);
    // console.log(image);

    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
    uploadImage(file);
  }

  const uploadImage = async (img) => {
    // console.log("hello");
    // console.log(img);
    const data = new FormData();
    data.append("file", img);
    const result = await addImage(data);
    if (result.code === 200) {
      console.log("Uploaded");
      //handleLoading(index, true);

      // console.log(res,"--data")
      // console.log("path", result.path);
      setPath(result.path);
    } else {
      console.log(`Error: ${result.status}`);
    }
  };
  function handleDeleteImage() {
    imageDelete(path);
    setImage("");
    // console.log(image);
    setShow(false);
  }
  const imageDelete = async (img) => {
    // console.log("hello");
    const data = new FormData();
    data.append("path", img);
    const result = await delImage(data);
    if (result.code === 200) {
      console.log("Deleted");
      //handleLoading(index, true);

      // console.log(res,"--data")
    } else {
      console.log(`Error: ${result.status}`);
    }
  };

  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingButton(true);
    const data = new FormData();
    data.append("first_name", formInputs.first_name);
    data.append("last_name", formInputs.last_name);
    if (path) {
      data.append("profile_image", path);
    } else {
      data.append("profile_image", image);
    }

    const result = await editAdmin(data);
    if (result.code === 200) {
      const token = localStorage.getItem("user");
      const payload = JSON.parse(token);
      // console.log(payload.email);
      const user = {
        first_name: result.adminUser.first_name,
        last_name: result.adminUser.last_name,
        image: result.adminUser.profile_image,
        email: payload.email,
      };
      // console.log(JSON.stringify(user));
      localStorage.setItem("user", JSON.stringify(user));
      const storedObjectString = localStorage.getItem("user");
      const storedObject = JSON.parse(storedObjectString);
      _set_user_profile(storedObject);
      setIsLoadingButton(false);
      navigate("/dashboard");
      enqueueSnackbar("Edited Successfully!", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const fetchProfile = async () => {
    const response = await detailAdmin();
    if (response.code === 200) {
      setIsLoading(false);
      setAdmin(response.admin);
      setFormInputs({
        first_name: response.admin.first_name,
        last_name: response.admin.last_name,
        profile_image: response.admin.profile_image,
        _id: response.admin.user_id._id,
      });
      if (response.admin.profile_image) {
        setImage(response.admin.profile_image);
      }
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Profile">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ marginBottom: "40px", marginTop: "20px" }}
      >
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "40px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>

          <Typography variant="h4" align="center" sx={{ flex: 1 }}>
            Profile
          </Typography>
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <Card sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}>
            <CardContent>
              <form
                autoComplete="off"
                onSubmit={handleSubmit}
                encType="multipart/form-data"
              >
                <Stack spacing={3}>
                  <Stack alignItems="center">
                    <Box sx={{ position: "relative" }}>
                      {previewUrl ? (
                        <Avatar
                          alt={"image"}
                          src={previewUrl}
                          sx={{ width: "120px", height: "120px" }}
                        />
                      ) : (
                        <Avatar
                          alt={"image"}
                          src={s3baseUrl + formInputs.profile_image}
                          sx={{ width: "120px", height: "120px" }}
                        />
                      )}
                      <IconButton
                        component="label"
                        sx={{
                          position: "absolute",
                          right: "-10px",
                          top: "70px",
                          backgroundColor: "transparent",
                          borderRadius: "50%",
                          zIndex: "1",
                          color: theme.palette.primary.main,
                        }}
                      >
                        <PhotoCamera />
                        <input
                          type="file"
                          id="image"
                          name="image"
                          accept="image/*"
                          hidden
                          onChange={handleFile}
                        />
                      </IconButton>
                    </Box>
                  </Stack>
                  <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    <TextField
                      fullWidth
                      label="First Name"
                      value={formInputs.first_name}
                      onChange={handleChange}
                      name="first_name"
                      required
                      inputProps={{
                        pattern: "[A-Za-z]{3,}",
                        title:
                          "Please enter at least 3 letters with only alphabetical characters.",
                      }}
                    />
                    <TextField
                      fullWidth
                      label="Last Name"
                      value={formInputs.last_name}
                      onChange={handleChange}
                      name="last_name"
                      inputProps={{
                        pattern: "[A-Za-z]{3,}",
                        title:
                          "Please enter at least 3 letters with only alphabetical characters.",
                      }}
                    />
                  </Stack>
                  {/* {show === false ? (
                    <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                      <label htmlFor="image" className="form-label">
                        Image:
                      </label>
                      <Input
                        type="file"
                        id="image"
                        name="image"
                        accept="image/*"
                        onChange={handleFile}
                      />
                    </Stack>
                  ) : null}
                  {show === true ? (
                    <Stack
                      position="relative"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <label htmlFor="image" className="form-label">
                        Image:
                      </label>
                      <img
                        alt="image"
                        src={previewUrl}
                        width="150"
                        style={{ maxWidth: "100%" }}
                      />
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleDeleteImage()}
                        style={{ position: "absolute", top: 0, right: 0 }}
                      >
                        <ClearIcon />
                      </IconButton>
                    </Stack>
                  ) : null} */}
                  <Stack alignItems="end">
                    <LoadingButton
                      size="medium"
                      type="submit"
                      variant="contained"
                      loading={isLoadingButton}
                      sx={{ width: "120px" }}
                    >
                      Edit
                    </LoadingButton>
                  </Stack>
                </Stack>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Page>
  );
};

export default Edit;
