import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { Page } from "src/components";
import { Iconify } from "src/components";
import { s3baseUrl } from "src/config/config";

import { detailPage, editPage } from "src/DAL/page";
import { listActiveTemplate } from "src/DAL/template";
const Edit = () => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const [choose_template, setChoose_template] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const [formInputs, setFormInputs] = useState({
    page_title: "",
    meta_title: "",
    meta_keywords: "",
    meta_description: "",

    redirect_user: "",
    choose_template: [],
    show_testimonial: "",

    faqs: "",
    page_status: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = new FormData();

    // console.log(brand_logo, "sdjkfhsdjkhsjkfhsjdkfhsdjk");
    data.append("page_title", formInputs.page_title);
    data.append("meta_title", formInputs.meta_title);
    data.append("meta_keywords", formInputs.meta_keywords);
    data.append("meta_description", formInputs.meta_description);

    data.append("redirect_user", formInputs.redirect_user);
    data.append(
      "choose_template",
      JSON.stringify(
        Array.isArray(formInputs.choose_template)
          ? formInputs.choose_template
          : [formInputs.choose_template]
      )
    );
    data.append("show_testimonial", formInputs.show_testimonial);

    data.append("faqs", formInputs.faqs);
    data.append("page_status", formInputs.page_status);
    // const formDataArray = Array.from(data.entries());

    // console.log(formDataArray);
    const result = await editPage(id, data);
    if (result.code === 200) {
      setIsLoading(false);
      navigate("/pages");
      enqueueSnackbar("Edited Successfully!", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  async function getDetailPage() {
    const response = await detailPage(id);
    if (response.code === 200) {
      // setPageslug(response.Page.page_title_slug);
      const templateIds = response.Page.choose_template.map(
        (choose_template) => choose_template._id
      );
      // console.log(data.instructor.first_name);
      setFormInputs({
        page_title: response.Page.page_title,
        meta_title: response.Page.meta_title,
        meta_keywords: response.Page.meta_keywords,
        meta_description: response.Page.meta_description,

        redirect_user: response.Page.redirect_user,
        choose_template: templateIds,
        show_testimonial: response.Page.show_testimonial,

        faqs: response.Page.faqs,
        page_status: response.Page.page_status,
      });
      // console.log(
      //   data.Page.use_general_social_media_links,
      //   "data.Page.use_general_social_media_linksdata.Page.use_general_social_media_links"
      // );
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  }

  const fetchActiveTemplates = async () => {
    const response = await listActiveTemplate();

    if (response.code === 200) {
      const templetes = response.templetes.map((templete) => ({
        id: templete._id,
        title: templete.template_name,
      }));
      setChoose_template(templetes);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  useEffect(() => {
    getDetailPage();
    fetchActiveTemplates();
  }, []);
  return (
    <Page title="Edit Page">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ marginBottom: "40px", marginTop: "20px" }}
      >
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "40px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>

          <Typography variant="h4" align="center" sx={{ flex: 1 }}>
            Edit Page
          </Typography>
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Page Title"
                  value={formInputs.page_title}
                  onChange={handleChange}
                  name="page_title"
                  required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />

                <TextField
                  fullWidth
                  label="Meta Title"
                  value={formInputs.meta_title}
                  onChange={handleChange}
                  name="meta_title"
                  required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />
              </Stack>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Meta Keywords"
                  value={formInputs.meta_keywords}
                  onChange={handleChange}
                  name="meta_keywords"
                  autoComplete="off"
                  required
                />
                <TextField
                  fullWidth
                  label="Redirect User"
                  value={formInputs.redirect_user}
                  onChange={handleChange}
                  name="redirect_user"
                  autoComplete="off"
                  required
                />
              </Stack>
              <TextField
                fullWidth
                label="Meta Description"
                value={formInputs.meta_description}
                onChange={handleChange}
                name="meta_description"
                autoComplete="off"
                required
                multiline
                rows={2}
              />
              <TextField
                fullWidth
                label="Choose Template"
                select
                value={formInputs.choose_template}
                onChange={handleChange}
                name="choose_template"
                required
                // SelectProps={{ multiple: true }}
              >
                {choose_template.map((choose_template) => (
                  <MenuItem key={choose_template.id} value={choose_template.id}>
                    {choose_template.title}
                  </MenuItem>
                ))}
              </TextField>

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Show Testimonial"
                  select
                  required
                  value={formInputs.show_testimonial}
                  onChange={handleChange}
                  name="show_testimonial"
                >
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </TextField>
              </Stack>

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="FAQS"
                  select
                  required
                  value={formInputs.faqs}
                  onChange={handleChange}
                  name="faqs"
                >
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </TextField>
                <TextField
                  fullWidth
                  label="Page Status"
                  select
                  required
                  value={formInputs.page_status}
                  onChange={handleChange}
                  name="page_status"
                >
                  <MenuItem value="true">Active</MenuItem>
                  <MenuItem value="false">Inactive</MenuItem>
                </TextField>
              </Stack>

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Edit
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </Grid>
    </Page>
  );
};

export default Edit;
